.App {
  text-align: left;
  background-color: white;
}

body {
  color: whitesmoke;
  font-family: 'Montserrat', sans-serif;
}

.Body {
  background-color: white;
  font-size: calc(5px + 1vmin);
  font-family: 'Montserrat', sans-serif;
  height: 100%;
}

.Headliner {
  font-family: 'Montserrat', sans-serif; 
}

.About {
  display: flex;
  position: fixed;
  left: 10vw;
  width: 75vw;
  font-size: calc(5px + 2vmin);
  font-family: 'Montserrat', sans-serif;
}

.Shop {
  display: flex;
  position: fixed;
  left: 10vw;
  width: 75vw;
  font-size: calc(5px + 2vmin);
  font-family: 'Montserrat', sans-serif;
}

.Home {
  display: flex;
  position: fixed;
  left: 10vw;
  width: 75vw;
  font-size: calc(5px + 2vmin);
  justify-content: left;
  font-family: 'Montserrat', sans-serif;
}


.Contact {
  display: flex;
  font-size: calc(5px + 2vmin);
  justify-content: left;
  font-family: 'Montserrat', sans-serif;
}

.App-logo {
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 5vh;
  display: flex;
  justify-content: space-between;
  font-size: calc(5px + 2vmin);
}

.App-link {
  color: #d132bf;
}

.App-link:hover {
  color: white;
}

.App-link-theo {
  visibility: hidden;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
}

.Nav {
  display: flex;
  float: right;
}

.NoBullets {
  padding: 2vh;
}

.Theo {
  visibility: hidden;
}

.Logo {
  display: flex;
  float: left;
}

.image-container {
  position: fixed;
  width: 100vw;
  z-index: -1;
}

.image-container img {
  position: fixed;
  padding: 0px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.image-container:before {
  content: "";
  display: block;
  padding-bottom: 66.67%;
}


.Index-page-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh
}

.Index-page-image img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 600px;
  max-height: 1333px;
}